import React from "react";
import Layout from "../Layout";
import Hero from "../components/Hero";
import AccentBar from "../components/AccentBar";
import IntroBlock from "../components/IntroBlock";
import { Col, Container, Row } from "react-bootstrap";
import ServiceCard from "../components/ServicesCard";
import { graphql, useStaticQuery } from "gatsby";
import LatestBlogPosts from "../components/LatestBlogPosts";
import SEO from "../components/SEO";
import CTABox from "../components/CTABox";

const services = [
  {
    title: "Climate Change & Sustainability ",
    to: "/services/ccas",
  },
  {
    title: "CC&S Management Model for Public & Private Sectors",
    to: "/services/ccas-pps",
  },
  {
    title: "CC&S Maturity Model (CCSMM)",
    to: "/services/maturity-model",
  },
  {
    title: "Quality Management Systems (ISO5001)",
    to: "/services/qms",
  },
  {
    title: "Carbon Optimisation",
    to: "/services/carbon-optimisation",
  },
];

const ServicesPage = () => {
  const query = useStaticQuery(graphql`
    query {
      one: file(
        relativePath: { eq: "pages/services/ccas/ccasBackground2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      two: file(relativePath: { eq: "pages/services/CCAS2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      three: file(
        relativePath: { eq: "pages/services/maturitymodel/mmbackground.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      four: file(relativePath: { eq: "pages/services/qms/qmsbackground.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      carbonOptimisation: file(
        relativePath: { eq: "pages/services/carbonoptimisation.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Our Services" />

      <Hero imageName="servicesBackground">
        <AccentBar>
          <h1>
            Climate change <br />
            and carbon market <br />
            advisory
          </h1>
        </AccentBar>
      </Hero>

      <IntroBlock>
        <h1>Services</h1>
        <p>
          TBL (Triple Bottom Line LTD) is a consultancy practice providing
          climate change and carbon market advisory services. TBL was founded by
          Kevin Williams in 2014. We have a track record of working with both
          private and public sector organisations across a range of sectors,
          providing practical, commercial insights in order to achieve specific
          policy and commercial objectives.
        </p>
      </IntroBlock>

      <section>
        <Container>
          <Row>
            <Col style={{textAlign: 'center', margin: 20}}>
              <h2>Our Services</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ServiceCard
                title={services[4].title}
                subtitle="Reducing costs and preparing for global carbon regulation."
                image={
                  query["carbonOptimisation"].childImageSharp.gatsbyImageData
                }
                to={services[4].to}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <ServiceCard
                title={services[0].title}
                image={query["one"].childImageSharp.gatsbyImageData}
                to={services[0].to}
              />
            </Col>
            <Col xs={12} sm={6}>
              <ServiceCard
                title={services[1].title}
                image={query["two"].childImageSharp.gatsbyImageData}
                to={services[1].to}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <ServiceCard
                title={services[2].title}
                image={query["three"].childImageSharp.gatsbyImageData}
                to={services[2].to}
              />
            </Col>
            <Col xs={12} sm={6}>
              <ServiceCard
                title={services[3].title}
                image={query["four"].childImageSharp.gatsbyImageData}
                to={services[3].to}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <div className="text-center">
          <h2>Emissions Trading Explained</h2>
          <div className="video-container">
            <iframe
              style={{ marginTop: 50 }}
              width="676"
              height="355"
              src="https://www.youtube.com/embed/yfNgsKrPKsg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <section>
        <div className="text-center">
          <h2>Latest Research</h2>
          <LatestBlogPosts />
        </div>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ServicesPage;
