import { Link } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './ServiceCard.module.scss';

interface ServiceCardProps {
    title: string;
    subtitle?: string;
    to: string;
    image: IGatsbyImageData
}

const ServiceCard = ({ title, to, image, subtitle}: ServiceCardProps) => {
    return <Link className={styles.card} to={to}>
        <GatsbyImage image={image} className={styles.image} alt=""/>
        <div className={styles.overlay}>
            <h4>{title}</h4>
            { subtitle ? <p>{ subtitle }</p> : null } 
        </div>
    </Link>
}

export default ServiceCard;